import { useEffect, useState } from "react"
import "./styles.css";

const Button = ({
  text,
  onClick,
  alternateColor,
  disabled,
  filled,
  delay,
  borderRoundSize,
}) => {
  const [styleName, setSytleName] = useState("");
  const [isDelayComplete, setIsDelayComplete] = useState(false);

  useEffect(() => {
    if (delay === null) return;

    setSytleName("hidden-button");
    const timerId = setTimeout(() => {
      setIsDelayComplete(true);
      setSytleName("hidden-button slide-up");
    }, delay * 1000);

    return () => clearTimeout(timerId);
  }, [delay]);

  const roundSize =
    borderRoundSize === "Medium" ? "border-round-medium" : "border-round-small";

  return (
    <button
      className={`styled-button ${
        alternateColor ? "green-text" : "white-text"
      } ${styleName} ${filled ? "filled" : "hollow"} ${roundSize}`}
      onClick={onClick}
      disabled={delay ? !isDelayComplete : disabled}
    >
      {text}
    </button>
  );
};

Button.defaultProps = {
  text: "",
  delay: null,
  onClick: undefined,
  alternateColor: false,
  disabled: false,
  filled: true,
  borderRoundSize: "Small",
};

export default Button;
