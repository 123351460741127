import { useEffect, useState } from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from "react-i18next";

export const SkinTypeCircles = ({ oilyScore, dryScore, normalScore }) => {
  const { t } = useTranslation("results");
  const [currentOilyScore, setCurrentOilyScore] = useState(0);
  const [currentDryScore, setCurrentDryScore] = useState(0);
  const [currentNormalScore, setCurrentNormalScore] = useState(0);

  useEffect(() => {
    const timerDryId = setTimeout(() => {
      setCurrentDryScore(dryScore);
    }, 605);
    const timerOilyId = setTimeout(() => {
      setCurrentOilyScore(oilyScore);
    }, 705);
    const timerNormalId = setTimeout(() => {
      setCurrentNormalScore(normalScore);
    }, 805);

    return () => {
      clearTimeout(timerDryId);
      clearTimeout(timerOilyId);
      clearTimeout(timerNormalId);
    };
  });

  return (
    <>
      <div className="skin-type-circle skin-position-0">
        <CircularProgressbarWithChildren
          strokeWidth={5}
          value={currentDryScore}
          background
          styles={buildStyles({
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            textColor: "black",
            pathColor: "#b7ee71",
            trailColor: "white",
          })}
        >
          <div className="skin-type-percentage">{dryScore}</div>
          <div className="skin-type-text">{t("Dry")}</div>
        </CircularProgressbarWithChildren>
      </div>
      <div className="skin-type-circle skin-position-1">
        <CircularProgressbarWithChildren
          strokeWidth={5}
          value={currentOilyScore}
          background
          styles={buildStyles({
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            textColor: "black",
            pathColor: "#83c3ff",
            trailColor: "white",
          })}
        >
          <div className="skin-type-percentage">{oilyScore}</div>
          <div className="skin-type-text">{t("Oily")}</div>
        </CircularProgressbarWithChildren>
      </div>
      <div className="skin-type-circle skin-position-2">
        <CircularProgressbarWithChildren
          strokeWidth={5}
          value={currentNormalScore}
          background
          styles={buildStyles({
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            textColor: "black",
            pathColor: "#e17177",
            trailColor: "white",
          })}
        >
          <div className="skin-type-percentage">{normalScore}</div>
          <div className="skin-type-text">{t("Normal")}</div>
        </CircularProgressbarWithChildren>
      </div>
    </>
  );
};
