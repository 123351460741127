const UserQuestions = {
  topHair: {
    0: {
      Q: "hair-question-1",
      options: [
        { text: "black", value: "Black", selected: false },
        { text: "brown", value: "Brown", selected: false },
        { text: "red", value: "Red", selected: false },
        { text: "blonde", value: "Blonde", selected: false },
      ],
      key: "hairColorName",
      multiAllowed: false,
    },
    1: {
      Q: "hair-question-2",
      options: [
        { text: "fine", value: "Fine", selected: false },
        { text: "normal", value: "Normal", selected: false },
        { text: "thick", value: "Thick", selected: false },
      ],
      key: "hairThickness",
      multiAllowed: false,
    },
  },
  shyne: {
    0: {
      Q: "hair-question-1",
      options: [
        { text: "black", value: "Black", selected: false },
        { text: "brown", value: "Brown", selected: false },
        { text: "red", value: "Red", selected: false },
        { text: "blonde", value: "Blonde", selected: false },
      ],
      key: "hairColorName",
      multiAllowed: false,
    },
    1: {
      Q: "hair-question-2",
      options: [
        { text: "fine", value: "Fine", selected: false },
        { text: "normal", value: "Normal", selected: false },
        { text: "thick", value: "Thick", selected: false },
      ],
      key: "hairThickness",
      multiAllowed: false,
    },
  },
  techdemo: {
    0: {
      Q: "techdemo-question-1",
      options: [
        { text: "brand", value: "Beauty Brand", selected: false },
        { text: "retailer", value: "Retailer", selected: false },
        {
          text: "service",
          value: "Service Provider",
          selected: false,
        },
        { text: "salon", value: "Salon", selected: false },
        { text: "others", value: "Others", selected: false },
      ],
      key: "customerType",
      multiAllowed: false,
    },
  },
  hejorganic: {
    0: {
      Q: "hejorganic-question-1",
      options: [
        { text: "hejorganic-answer-1", value: "Cleansing", selected: false },
        { text: "hejorganic-answer-2", value: "Travel Size", selected: false },
        {
          text: "hejorganic-answer-3",
          value: "Sets",
          selected: false,
        },
        { text: "hejorganic-answer-4", value: "Care", selected: false },
      ],
      key: "productType",
      multiAllowed: true,
    },
  },
};

export default UserQuestions;
