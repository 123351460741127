import { useRive } from "@rive-app/react-canvas";

export const RiveAnimator = ({ fileName, animationName, onAnimationEnd }) => {
  const handleOnStop = async () => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    onAnimationEnd();
  };

  const { RiveComponent } = useRive({
    src: fileName,
    animations: animationName,
    autoplay: true,
    onStop: handleOnStop,
  });

  return <RiveComponent />;
};
