export const ResultCategories = {
  glueckauf: ["Skin Type", "Skin Age", "Hair Color", "Hair Gloss"],
  shyne: [
    "Skin Type",
    "Skin Age",
    "Hair Color",
    "Hair Gloss",
    "Product Recommendation",
  ],
  topHair: [
    "Skin Type",
    "Skin Age",
    "Hair Color",
    "Hair Gloss",
    "Product Recommendation",
  ],
  techdemo: [
    "Skin Type",
    "Skin Age",
    "Wrinkles",
    "Puffy Eyes",
    "Irritation",
    "Hair Color",
    "Hair Gloss",
  ],
};

export const ResultInfo = {
  "Skin Age": "skin-age-info",
  no: "no-wrinkles-info",
  few: "few-wrinkles-info",
  many: "many-wrinkles-info",
  "Puffy Eyes": "puffy-eyes-info",
  "No Irritation": "no-irritation-info",
  "Irritation Detected": "irritation-info",
  "Hair Color": "hair-color-info",
};
