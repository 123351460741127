import { useEffect, useState } from "react";
import { IoChevronBackOutline } from "react-icons/io5";
import { ProductCards } from "../ProductCards/ProductCards";
import CircularProgress from "@mui/material/CircularProgress";
import { RecommendationUrl } from "../../constants";
import "./styles.css";
import { useTranslation } from "react-i18next";

export const ProductRecommendation = ({ userId, scanType, onBackClick }) => {
  const { t } = useTranslation("recommendation");
  const [products, setProducts] = useState(null);

  useEffect(() => {
    const getRecommendations = async () => {
      try {
        const response = await fetch(
          `${RecommendationUrl}?partnerName=shyne&collectionName=tradefair&eventName=${scanType}&userId=${userId}`
        );
        if (response.ok) {
          const data = await response.json();
          setProducts(data);
        } else {
          throw new Error("Failed to fetch!");
        }
      } catch (error) {
        // Leaving it empty as no message is displayed
      }
    };

    getRecommendations();
  }, [scanType, userId]);

  return (
    <div className="product-recommend">
      <div className="logo-bar">
        <img
          className="logo-img"
          src="partner/shyne_logo.png"
          alt="SHYNE Logo"
        />
        <IoChevronBackOutline
          className="back-icon"
          onClick={() => onBackClick()}
        />
      </div>
      <div className="product-individual">
        <h4 className="page-info">{t("title-1")}</h4>
        <h4 className="page-info">{t("title-2")}</h4>
      </div>
      {products ? (
        <>
          {products.basic && (
            <div className="basic-products">
              <h4 className="products-title">{t("basic-title")}</h4>
              <p className="products-text">{t("basic-info")}</p>
              <ProductCards products={products.basic} />
            </div>
          )}
          {products.gloss && (
            <div className="gloss-products">
              <h4 className="products-title">{t("gloss-title")}</h4>
              <p className="products-text">{t("gloss-info")}</p>
              <ProductCards products={products.gloss} />
            </div>
          )}
          {products.color && (
            <div className="color-products">
              <h4 className="products-title">{t("color-title")}</h4>
              <p className="products-text">{t("color-info")}</p>
              <ProductCards products={products.color} />
            </div>
          )}
        </>
      ) : (
        <div
          style={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
            color: "#000",
          }}
        >
          <CircularProgress color="inherit" />
        </div>
      )}
    </div>
  );
};
