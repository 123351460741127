import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ProcessSelector } from "../ProcessSelector/ProcessSelector";
import { Pipeline } from "../Pipeline/Pipeline";

export const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ProcessSelector />} />
        <Route
          path="/techdemo"
          element={<Pipeline scanType="techdemo" lang="de" />}
        />
        <Route
          path="/topHair"
          element={<Pipeline scanType="topHair" lang="en" />}
        />
        <Route
          path="/shyne"
          element={<Pipeline scanType="shyne" lang="de" />}
        />
        <Route
          path="/glueckauf"
          element={<Pipeline scanType="glueckauf" lang="de" />}
        />
        <Route
          path="/hejorganic"
          element={<Pipeline scanType="hejorganic" lang="de" />}
        />
        <Route path="*" />
      </Routes>
    </Router>
  );
};
