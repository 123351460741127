import { useTranslation } from "react-i18next";
import Button from "../Button/Button";
import "./styles.css";

export const HairdresserPage = ({ onSkipClick, onContinueClick }) => {
  const { t } = useTranslation("hairScan");

  return (
    <div className="hair-page">
      <div className="hair-page-img">
        <video
          src="Sphere.mov"
          playsInline
          autoPlay
          loop
          muted
          width="320"
          style={{ width: "70vmin", maxWidth: "480px", height: "70vmin" }}
        />
      </div>
      <div className="hair-page-text">
        <div
          style={{
            width: "80%",
            maxWidth: "480px",
            display: "grid",
            justifyContent: "center",
          }}
        >
          <h2 style={{ fontSize: "1rem", color: "white" }}>
            {t("hairdresser-title")}
          </h2>
          <p style={{ fontSize: "1rem", color: "white" }}>
            {t("hairdresser-guide-1")}
          </p>
          <p style={{ fontSize: "1rem", color: "white" }}>
            {t("hairdresser-guide-2")}
          </p>
        </div>
      </div>
      <div className="hair-page-skip">
        <Button
          text={t("continue-without")}
          onClick={() => onSkipClick()}
          alternateColor={false}
          filled={false}
        />
      </div>
      <div className="hair-page-continue">
        <Button
          text={t("continue")}
          onClick={() => onContinueClick()}
          alternateColor={true}
        />
      </div>
    </div>
  );
};
