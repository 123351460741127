import Webcam from "react-webcam";
import "./styles.css";
import { Info } from "../Info/Info";
import Button from "../Button/Button";
import { LightBar } from "../LightBar/LightBar";
import { RiveAnimator } from "../RiveAnimator/RiveAnimator";
import {
  LightThreshold,
  LightAIInputSize,
  videoConstraints,
  FaceRotationFilename,
  AddImageUrl,
} from "../../constants";
import {
  checkFaceRotation,
  checkFrontFace,
  resizeCanvasCtx,
  videoToCoverCanvasCoordinates,
} from "./utilities";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export const FaceAI = ({
  setCameraLoaded,
  runFaceModel,
  runLightModel,
  onComplete,
  userId,
  scanType,
}) => {
  const camContainerRef = useRef(null);
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const { t } = useTranslation("faceScan");

  const maskRef = useRef(null);
  const maskBounds = useRef([0, 0, 0, 0]);
  const coverImageCoords = useRef([0, 0, 0, 0]);

  const [scanStep, setScanStep] = useState(0);
  const [scanInfo, setScanInfo] = useState("tutorial");

  // Face capture parameters
  const [frontFaceAligned, setFrontFaceAligned] = useState(false);
  const [lightValue, setLightValue] = useState(0);
  const faceBoxCanvas = useRef(null);

  const [showCaptureFlash, setShowCaptureFlash] = useState(false);
  const isAnimationEnded = useRef(false);

  const resetCanvas = () => {
    const ctx = canvasRef.current.getContext("2d");
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
  };

  const setMaskBounds = () => {
    const { x, y, width, height } = maskRef.current.getBoundingClientRect();
    maskBounds.current = [x, y, width, height];
  };

  const setCanvasOverlaySize = () => {
    const videoElement = webcamRef.current.video;

    // Update canvas dimensions - Explicitly not defined in CSS
    canvasRef.current.width = videoElement.offsetWidth;
    canvasRef.current.height = canvasRef.current.offsetHeight;

    // Get Image Coordinates for "objectFit: cover" property
    const containerRect = camContainerRef.current.getBoundingClientRect();
    const scaleX = containerRect.width / videoElement.videoWidth;
    const scaleY = containerRect.height / videoElement.videoHeight;
    const scale = Math.max(scaleX, scaleY);
    const actualWidth = videoElement.videoWidth * scale;
    const actualHeight = videoElement.videoHeight * scale;
    const actualXOffset = Math.round(
      Math.max((actualWidth - containerRect.width) / 2, 0)
    );
    const actualYOffset = Math.round(
      Math.max((actualHeight - containerRect.height) / 2, 0)
    );

    coverImageCoords.current = [
      actualXOffset,
      actualYOffset,
      actualWidth,
      actualHeight,
    ];

    faceBoxCanvas.current = document.createElement("canvas", {
      willReadFrequently: true,
    });
    faceBoxCanvas.current.width = LightAIInputSize;
    faceBoxCanvas.current.height = LightAIInputSize;
  };

  const updateAnimationState = () => {
    isAnimationEnded.current = true;
  };

  const runAIDetection = async () => {
    let faceSidePos = 0;
    let scanStep = 1;
    let captureTimer = null,
      sideDelayTimer = null;

    // Cloud API
    const url = AddImageUrl + `?collectionName=tradefair&eventName=${scanType}`;

    try {
      while (scanStep !== "finish") {
        if (isAnimationEnded.current === false) {
          await new Promise((resolve) =>
            requestAnimationFrame(() => resolve())
          );
          continue;
        }

        const video = webcamRef.current.video;

        const faces = await runFaceModel(video);

        const canvasFaceCoords = videoToCoverCanvasCoordinates(
          faces,
          canvasRef.current.width,
          canvasRef.current.height,
          video.videoWidth,
          video.videoHeight,
          coverImageCoords.current[2],
          coverImageCoords.current[3],
          coverImageCoords.current[0],
          coverImageCoords.current[1]
        );

        if (canvasFaceCoords !== null) {
          // Clean up canvas
          const ctx = canvasRef.current.getContext("2d");
          ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

          // For visualization of face detection
          // drawDetection(canvasFaceCoords, ctx);

          switch (scanStep) {
            case 1:
              const faceBoxCtx = faceBoxCanvas.current.getContext("2d");
              resizeCanvasCtx(video, faces[0].box, faceBoxCtx, true);
              const imageData = faceBoxCtx.getImageData(
                0,
                0,
                LightAIInputSize,
                LightAIInputSize
              );
              const lightScore = await runLightModel(imageData);
              const distanceSuccess = checkFrontFace(
                canvasFaceCoords,
                true,
                maskBounds.current[0],
                maskBounds.current[1],
                maskBounds.current[2],
                maskBounds.current[3]
              );
              setFrontFaceAligned(distanceSuccess);

              if (distanceSuccess) {
                setLightValue(lightScore);
                setScanInfo("inspectLight");
              } else {
                setLightValue(0);
                setScanInfo("facePosition");
              }

              if (distanceSuccess && lightScore >= LightThreshold) {
                if (captureTimer === null) captureTimer = Date.now();
                setScanInfo("holdPosition");
              } else {
                captureTimer = null;
              }

              // Step complete
              if (captureTimer !== null && Date.now() - captureTimer >= 800) {
                // Download in original resolution
                const imageSrc = webcamRef.current.getScreenshot();
                const imageData = imageSrc
                  .toString()
                  .replace(/^data:image\/jpeg;base64,/, "");

                // Intentionally ignoring response, to mimic fire-and-forget behavior
                fetch(url, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    image: imageData,
                    userId: userId,
                    filename: "originalImage",
                    runAI: true,
                    saveAiResults: true,
                  }),
                });

                setScanInfo("faceSides");
                setScanStep((prevValue) => prevValue + 1);
                scanStep += 1;
                captureTimer = null;
              }
              break;
            case 2:
              if (faceSidePos > 3) {
                scanStep = "finish";
                break;
              }

              if (sideDelayTimer === null) {
                const maskNoseY =
                  0.65 * maskBounds.current[3] + maskBounds.current[1];
                const checkSuccess = checkFaceRotation(
                  faceSidePos,
                  maskNoseY,
                  canvasFaceCoords,
                  ctx
                );

                if (checkSuccess) {
                  if (captureTimer === null) captureTimer = Date.now();
                } else {
                  captureTimer = null;
                }

                // Hold side position - 200ms time
                if (captureTimer && Date.now() - captureTimer >= 200) {
                  // Send image to cloud
                  setShowCaptureFlash(true);
                  const imageSrc = webcamRef.current.getScreenshot();
                  const imageData = imageSrc
                    .toString()
                    .replace(/^data:image\/jpeg;base64,/, "");

                  // Intentionally ignoring response, to mimic fire-and-forget behavior
                  fetch(url, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      image: imageData,
                      userId: userId,
                      filename: FaceRotationFilename[faceSidePos],
                      runAI: false,
                      saveAiResults: false,
                    }),
                  }).catch();
                  sideDelayTimer = Date.now();
                }
              }

              // Wait for flash to complete - 110ms animation
              if (sideDelayTimer && Date.now() - sideDelayTimer >= 110) {
                faceSidePos += 1;
                captureTimer = null;
                sideDelayTimer = null;
                setShowCaptureFlash(false);
              }
              break;
            default:
              break;
          }
        }

        await new Promise((resolve) => requestAnimationFrame(() => resolve()));
      }
      // Scan complete
      resetCanvas();
      onComplete();
    } catch (error) {
      return;
    }
  };

  return (
    <>
      <div
        ref={camContainerRef}
        className="webcam"
        style={{ opacity: `${scanStep === 0 ? 0 : 1}` }}
      >
        <Webcam
          audio={false}
          mirrored={true}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "center",
          }}
          forceScreenshotSourceSize={true}
          imageSmoothing={true}
          onUserMedia={() => {
            const timerId = setTimeout(() => setCameraLoaded(true), 500);
            return () => clearTimeout(timerId);
          }}
          onLoadedMetadata={() => setCanvasOverlaySize()}
          onUserMediaError={() => setCameraLoaded(false)}
        />
      </div>
      <div
        className={`partial-overlay detection-canvas ${
          scanStep === 1 ? "face-alignment" : ""
        }`}
      >
        <canvas
          ref={canvasRef}
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </div>
      {scanStep === 0 && (
        <div className="full-overlay mask">
          <video
            src="demo-videos/tutorial-iqonic.mov"
            playsInline
            autoPlay
            loop
            muted
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </div>
      )}
      {scanStep === 1 && (
        <div className="partial-overlay mask face-alignment">
          <img
            ref={maskRef}
            src="FaceMask_Green.svg"
            width={100}
            alt="Face Position"
            style={{
              width: "90vmin",
              height: "90vmin",
              maxWidth: "480px",
              maxHeight: "480px",
              opacity: frontFaceAligned ? 1 : 0,
            }}
            onLoad={() => setMaskBounds()}
          />
        </div>
      )}
      {scanStep === 1 && (
        <div className="partial-overlay mask face-alignment">
          <div
            style={{
              width: "90vmin",
              height: "90vmin",
              maxWidth: "480px",
              maxHeight: "480px",
              opacity: frontFaceAligned ? 0 : 1,
            }}
          >
            <RiveAnimator
              fileName="FaceMask_Animation.riv"
              animationName="Animation"
              onAnimationEnd={updateAnimationState}
            />
          </div>
        </div>
      )}

      {scanStep === 2 && (
        <div className={`full-overlay ${showCaptureFlash ? "flash" : ""}`} />
      )}
      <div className="scan-info cam-start">
        <Info scanStep={scanInfo} />
        <div className="scan-info-interact">
          {scanStep === 0 && (
            <Button
              text={t("continue")}
              delay={5}
              onClick={() => {
                setScanStep(1);
                setScanInfo("facePosition");
                runAIDetection();
              }}
              alternateColor={true}
            />
          )}
          {scanStep === 1 && <LightBar value={lightValue} />}
        </div>
      </div>
    </>
  );
};
