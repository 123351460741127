import './App.css';
import { AppRouter } from './components/AppRouter/AppRouter';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

function App() {
  return (
    <div className="App">
      <I18nextProvider i18n={i18n}>
        <AppRouter /> 
      </I18nextProvider>
    </div>
  );
}

export default App;
