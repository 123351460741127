import { useTranslation } from "react-i18next";
import "./styles.css";

export const Info = ({ scanStep }) => {
  const { t } = useTranslation("faceScan");

  const infoText = () => {
    switch (scanStep) {
      case "tutorial":
        return <div className="info-text"></div>;
      case "inspectLight":
        return (
          <div className="info-text">
            <p className="white-text">{t("inspectLight")}</p>
          </div>
        );
      case "facePosition":
        return (
          <div className="info-text">
            <p className="white-text">{t("facePosition")}</p>
          </div>
        );
      case "holdPosition":
        return (
          <div className="info-text">
            <p className="white-text">{t("holdPosition")}</p>
          </div>
        );
      case "faceSides":
        return (
          <div className="info-text">
            <p className="white-text">{t("faceSides")}</p>
          </div>
        );
      case "error":
        return (
          <div className="info-text">
            <p className="white-text">{t("error")}</p>
          </div>
        );
      default:
        return <div className="info-text" />;
    }
  };

  return <>{infoText()}</>;
};
