import { useState } from "react";
import Button from "../Button/Button";
import { useTranslation } from "react-i18next";
import "./styles.css";

export const StartPage = ({ scanType, onStart }) => {
  const { t } = useTranslation("startPage");
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <div className="loading-img">
        <video
          src="Sphere.mov"
          playsInline
          autoPlay
          loop
          muted
          width="320"
          style={{
            width: "100vmin",
            maxWidth: "640px",
            height: "100vmin",
            maxHeight: "640px",
          }}
        />
        {scanType === "glueckauf" && (
          <img
            src="partner/glueckauf_logo.png"
            alt={scanType}
            width="320"
            style={{
              width: "40vmin",
              maxWidth: "200px",
            }}
          />
        )}
        {(scanType === "shyne" || scanType === "topHair") && (
          <img
            src="partner/shyne_logo_transparent.png"
            alt={scanType}
            width="320"
            style={{
              width: "40vmin",
              maxWidth: "200px",
            }}
          />
        )}
      </div>
      <div className="scan-info">
        <div className="scan-info-text">
          <h2 style={{ fontSize: "1.25rem", color: "white" }}>
            {isLoading ? t("title-loading") : t("title")}
          </h2>
          <p
            style={{
              paddingTop: "0.75rem",
              fontSize: "0.875rem",
              color: "white",
            }}
          >
            {isLoading ? t("description-loading") : t("description")}
          </p>
        </div>
        {!isLoading && (
          <div className="start-scan-info-interact">
            <Button
              text={t("start")}
              onClick={() => {
                setIsLoading(true);
                onStart();
              }}
              alternateColor={true}
            />
            <p className="terms-text">{t("terms-text")}</p>
          </div>
        )}
      </div>
    </>
  );
};
