import Webcam from "react-webcam";
import HeadText from "./HeadText";
import { BackCamConstraints } from "../../constants";
import { useState, useRef, useEffect } from "react";
import "./styles.css";
import { useTranslation } from "react-i18next";

export const HeadScan = ({ onBack, onFinish }) => {
  const { t } = useTranslation("hairScan");
  const webcamRef = useRef(null);

  const [showCaptureFlash, setShowCaptureFlash] = useState(false);
  const [cameraLoaded, setCameraLoaded] = useState(false);
  const [scanStep, setScanStep] = useState(0);

  useEffect(() => {
    if (!showCaptureFlash) return;
  }, [showCaptureFlash]);

  const onCaptureImage = () => {
    setShowCaptureFlash(true);
    const timerId = setTimeout(() => {
      setShowCaptureFlash(false);

      setScanStep((prevValue) => prevValue + 1);
      if (scanStep === 2) onFinish();
    }, 110); // Delay here is same as flash delay in css

    return () => clearTimeout(timerId);
  };

  return (
    <div className="head-scan">
      <div className="head-scan-webcam">
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={BackCamConstraints}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "center",
          }}
          forceScreenshotSourceSize={true}
          imageSmoothing={true}
          onUserMedia={() => {
            const timerId = setTimeout(() => setCameraLoaded(true), 500);
            return () => clearTimeout(timerId);
          }}
          onUserMediaError={() => setCameraLoaded(false)}
        />
      </div>
      {scanStep === 0 && (
        <div className="head-scan-img">
          <img
            src="hairdresser_guide.svg"
            width={100}
            alt="Back Head Guide"
            style={{
              width: "80vmin",
              maxWidth: "480px",
              height: "80vmin",
            }}
          />
        </div>
      )}
      <div
        className={`head-scan-tutorial-background ${
          scanStep > 0 ? "hidden" : "overlayed"
        }`}
      />
      {scanStep > 0 && (
        <div className={`full-overlay ${showCaptureFlash ? "flash" : ""}`} />
      )}
      <div className="head-scan-text">
        <p style={{ fontSize: "1rem", color: "white" }}>
          {t(HeadText[scanStep])}
        </p>
      </div>
      <div className="head-scan-interact">
        {scanStep === 0 && (
          <div
            className={`hair-tutorial-flex-bar ${
              cameraLoaded ? "show-up-buttons" : ""
            }`}
          >
            <button
              className="hair-tutorial-back-button"
              onClick={() => onBack()}
            >
              {t("back")}
            </button>
            <button
              className="hair-tutorial-start-button"
              onClick={() => setScanStep(1)}
            >
              {t("start")}
            </button>
          </div>
        )}
        {scanStep > 0 && (
          <button className="round-capture-button" onClick={onCaptureImage} />
        )}
      </div>
    </div>
  );
};
