import { useEffect, useState } from "react";
import { FaCaretUp } from "react-icons/fa";
import { MdCheck } from "react-icons/md";
import { LightThreshold } from "../../constants";
import "./styles.css";

export const LightBar = ({value}) => {
    const [sliderPosition, setSliderPosition] = useState(0);

    const calculateCaretPosition = (value) => {
      if (value >= LightThreshold) {
        return 45;
      } else if (value === 0) {
        return 90;
      } else {
        return Math.round(45 + (value - 1) * (90 - 45));
      }
    };

    useEffect(() => {
      const xPosition = calculateCaretPosition(value);
      setSliderPosition(xPosition);
    }, [value]);

    return (
      <div className="linear-bar">
        {value < LightThreshold && (
          <>
            <FaCaretUp
              className="icon"
              style={{ left: `${sliderPosition}%` }}
            />
            <FaCaretUp
              className="icon"
              style={{ right: `${sliderPosition}%` }}
            />
          </>
        )}
        <MdCheck className={`tick ${value >= LightThreshold ? "green" : ""}`} />
      </div>
    );
}