import TextField from "@mui/material/TextField";
import "./styles.css"
import { useTranslation } from "react-i18next";

export const Email = ({ email, setEmail, setIsEmailValid }) => {
  const { t } = useTranslation("userData");
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleTextInputChange = (event) => {
    setEmail(event.target.value);
    const isValidEmail = emailRegex.test(event.target.value);
    setIsEmailValid(isValidEmail);
  };

  return (
    <div className="email-content">
      <div className="email-info">
        <h4>E-Mail</h4>
        <p>{t("email-detail")}</p>
      </div>
      <div className="email-input-field">
        <TextField
          placeholder={t("email-placeholder")}
          variant="outlined"
          value={email}
          onChange={handleTextInputChange}
          margin="none"
          hiddenLabel={true}
          fullWidth
        />
      </div>
    </div>
  );
};
