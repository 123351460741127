import { useNavigate } from "react-router-dom";
import "./styles.css"
import Button from "../Button/Button";

export const ProcessSelector = () => {
  const navigate = useNavigate();

  const handleClick = (scanType) => {
    navigate(scanType);
  };

  return (
    <div className="selector">
      <Button
        text={"All"}
        onClick={() => {
          handleClick("/techdemo");
        }}
      />
      <Button
        text={"Hair"}
        onClick={() => {
          handleClick("/topHair");
        }}
      />
    </div>
  );
};