import QRCode from "react-qr-code";
import "./styles.css";
import { useTranslation } from "react-i18next";

export const DesktopPage = () => {
  const { t } = useTranslation("startPage");

  return (
    <div className="desktop-page">
      <div className="desktop-info">
        <h2 className="desktop-title">{t("desktop-title")}</h2>
        <p className="desktop-detail">{t("desktop-detail")}</p>
        <QRCode
          style={{
            height: "40vmin",
            width: "40vmin",
            maxWidth: "200px",
            maxHeight: "200px",
            marginTop: "3rem",
          }}
          bgColor="#f3f3f3"
          value={window.location.href}
          level="M"
        />
      </div>
      <div className="source-logo">
        <p className="source-text">{t("desktop-logo-power")}</p>
        <img src="iqonic-logo.png" alt={"IQONIC.AI"} width={100} />
      </div>
    </div>
  );
};
