import { useTranslation } from "react-i18next";
import Button from "../Button/Button";
import "./styles.css";

export const FormOptions = ({
  options,
  optionName,
  multiAllowed,
  selectedOptions,
  onSelect,
}) => {
  const { t } = useTranslation("userData");

  return (
    <div className="form-options">
      {options.map((option, index) => (
        <Button
          key={index}
          text={t(option.text)}
          onClick={() => onSelect(optionName, option.value)}
          alternateColor={
            multiAllowed
              ? selectedOptions === undefined
                ? true
                : !selectedOptions.includes(option.value)
              : option.value !== selectedOptions
          }
          borderRoundSize={"Medium"}
          filled={
            multiAllowed
              ? selectedOptions === undefined
                ? false
                : selectedOptions.includes(option.value)
              : option.value === selectedOptions
          }
          disabled={false}
        />
      ))}
    </div>
  );
};
