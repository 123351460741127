import { useState } from "react";
import "./styles.css";
import { FaceAI } from "../FaceAI/FaceAI";
import { StartPage } from "./StartPage";
import { DesktopPage } from "./DesktopPage";
import { isDesktop } from "react-device-detect";

export const StartScan = ({
  loadModels,
  modelsLoaded,
  runFaceModel,
  runLightModel,
  updateAnalysisPhase,
  userId,
  scanType,
}) => {
  // Init phase for scan
  const [cameraLoaded, setCameraLoaded] = useState(false);
  const [startPressed, setStartPressed] = useState(false);

  return (
    <>
      {isDesktop ? (
        <DesktopPage />
      ) : (
        <div className="scan">
          {startPressed && modelsLoaded && (
            <div className={`scan-camera ${cameraLoaded ? "fade-in" : ""}`}>
              <FaceAI
                userId={userId}
                scanType={scanType}
                setCameraLoaded={setCameraLoaded}
                runFaceModel={runFaceModel}
                runLightModel={runLightModel}
                onComplete={updateAnalysisPhase}
              />
            </div>
          )}
          {!cameraLoaded && (
            <StartPage
              scanType={scanType}
              onStart={() => {
                loadModels();
                setStartPressed(true);
              }}
            />
          )}
        </div>
      )}
    </>
  );
};
