export const HairColors = ["#433b35", "#a9836e", "#695f55", "#2d2523"];
export const SkinTypeColors = {
  Oily: "#83c3ff",
  Dry: "#b7ee71",
  Normal: "#e17177",
  Combined: "#86E5E0",
};

export const HairGlossColors = {
  Glossy: "#b7ee71",
  Dull: "#e17177",
};

export const ImagePathMap = {
  "Skin Type": "skinTypeImage",
  "Skin Age": "ageImage",
  Wrinkles: "wrinklesImage",
  "Puffy Eyes": "puffyImage",
  Irritation: "diseaseImage",
  "Hair Color": "hairColorImage",
  "Hair Gloss": "hairGlossyImage",
};
