// FRONT CAMERA
export const videoConstraints = {
  facingMode: "user",
  frameRate: { ideal: 30 },
  width: 3000,
  height: 2000,
};

export const BackCamConstraints = {
  facingMode: "environment",
  frameRate: { ideal: 30 },
  width: 3000,
  height: 2000,
};

// LIGHT AI
export const LightAIInputSize = 160;
export const LightThreshold = 0.1; // value between 0 - 1

export const PredictionConfidence = [
  0, 0, 0.06, 0.06, 0.06, 0.06, 0.06, 0.07, 0.07, 0.12, 0.12, 0.12, 0.12, 0.13,
  0.13, 0.19, 0.19, 0.24, 0.25, 0.25, 0.26, 0.3, 0.3, 0.3, 0.3, 0.31, 0.37,
  0.37, 0.37, 0.38, 0.42, 0.42, 0.43, 0.43, 0.43, 0.43, 0.43, 0.43, 0.43, 0.44,
  0.44, 0.44, 0.44, 0.44, 0.49, 0.49, 0.49, 0.49, 0.49, 0.5, 0.5, 0.5, 0.5, 0.5,
  0.5, 0.5, 0.51, 0.51, 0.51, 0.55, 0.55, 0.55, 0.56, 0.56, 0.56, 0.56, 0.56,
  0.57, 0.61, 0.61, 0.62, 0.62, 0.62, 0.62, 0.62, 0.62, 0.62, 0.62, 0.63, 0.63,
  0.63, 0.63, 0.67, 0.68, 0.68, 0.68, 0.68, 0.68, 0.68, 0.68, 0.68, 0.68, 0.68,
  0.68, 0.68, 0.68, 0.69, 0.69, 0.69, 0.69, 0.69, 0.69, 0.69, 0.69, 0.7, 0.7,
  0.74, 0.74, 0.74, 0.74, 0.74, 0.74, 0.74, 0.74, 0.74, 0.74, 0.74, 0.74, 0.74,
  0.75, 0.75, 0.75, 0.75, 0.75, 0.75, 0.76, 0.8, 0.8, 0.8, 0.8, 0.8, 0.8, 0.8,
  0.8, 0.81, 0.81, 0.81, 0.81, 0.81, 0.81, 0.81, 0.81, 0.81, 0.81, 0.86, 0.86,
  0.87, 0.87, 0.87, 0.87, 0.87, 0.87, 0.87, 0.87, 0.87, 0.87, 0.87, 0.87, 0.87,
  0.87, 0.87, 0.87, 0.87, 0.87, 0.87, 0.87, 0.87, 0.88, 0.88, 0.88, 0.88, 0.93,
  0.93, 0.93, 0.93, 0.93, 0.93, 0.93, 0.93, 0.94, 0.94, 0.94, 0.94, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
];

export const FaceRotationAngles = [
  { x: 0.25 },
  { x: 0.42 },
  { x: 0.58 },
  { x: 0.75 },
];

export const FaceRotationFilename = [
  "leftHighRotationImage",
  "leftLessRotationImage",
  "rightLessRotationImage",
  "rightHighRotationImage",
];

export const AddImageUrl =
  "https://uploadandprocessimage-r2ohj2pzsq-ey.a.run.app"; //"http://127.0.0.1:5001/sqin-ai/europe-west3/uploadAndProcessImage";

export const UpdateUserInfoUrl =
  "https://addadditionaluserinfo-r2ohj2pzsq-ey.a.run.app"; //"http://127.0.0.1:5001/sqin-ai/europe-west3/addAdditionalUserInfo";

export const AiResultsUrl = "https://getairesults-r2ohj2pzsq-ey.a.run.app"; //"http://127.0.0.1:5001/sqin-ai/europe-west3/getAiResults";

export const EmailUrl = "https://sendemail-r2ohj2pzsq-ey.a.run.app"; //"http://127.0.0.1:5001/sqin-ai/europe-west3/sendEmail"

export const RecommendationUrl =
  "https://getproductrecommendation-r2ohj2pzsq-ey.a.run.app";

export const OnlyFaceScan = ["techdemo", "shyne", "glueckauf", "hejorganic"];