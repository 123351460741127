import { useState } from "react";
import "./styles.css";
import { useTranslation } from "react-i18next";

const Info = {
  Glossy: "glossy-card-info",
  Dull: "dull-card-info",
};

export const HairGlossTypeCard = () => {
  const { t } = useTranslation("results");
  const [activeTab, setActiveTab] = useState("Glossy");
  const tabs = ["Glossy", "Dull"];

  return (
    <div className="model-card">
      <div className="button-container">
        {tabs.map((tab) => (
          <button
            key={tab}
            className={`tab-button ${activeTab === tab ? "active" : ""}`}
            onClick={() => setActiveTab(tab)}
          >
            {t(tab)}
          </button>
        ))}
      </div>
      <div className="content-container">
        <p className="content-text">{t(Info[activeTab])}</p>
      </div>
    </div>
  );
};
