import { useEffect, useState } from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from "react-i18next";

export const HairGlossCircles = ({ glossyScore, dullScore }) => {
  const { t } = useTranslation("results");
  const [currentGlossyScore, setCurrentGlossyScore] = useState(0);
  const [currentDullScore, setCurrentDullScore] = useState(0);

  useEffect(() => {
    const timerGlossyId = setTimeout(() => {
      setCurrentGlossyScore(glossyScore);
    }, 605);
    const timerDullId = setTimeout(() => {
      setCurrentDullScore(dullScore);
    }, 705);

    return () => {
      clearTimeout(timerDullId);
      clearTimeout(timerGlossyId);
    };
  });

  return (
    <>
      <div className="skin-type-circle skin-position-0">
        <CircularProgressbarWithChildren
          strokeWidth={5}
          value={currentGlossyScore}
          background
          styles={buildStyles({
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            textColor: "black",
            pathColor: "#b7ee71",
            trailColor: "white",
          })}
        >
          <div className="skin-type-percentage">{glossyScore}</div>
          <div className="skin-type-text">{t("Glossy")}</div>
        </CircularProgressbarWithChildren>
      </div>
      <div className="skin-type-circle skin-position-1">
        <CircularProgressbarWithChildren
          strokeWidth={5}
          value={currentDullScore}
          background
          styles={buildStyles({
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            textColor: "black",
            pathColor: "#e17177",
            trailColor: "white",
          })}
        >
          <div className="skin-type-percentage">{dullScore}</div>
          <div className="skin-type-text">{t("Dull")}</div>
        </CircularProgressbarWithChildren>
      </div>
    </>
  );
};
