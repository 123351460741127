import { useState } from "react";
import "./styles.css";

export const ColorOptions = ({ options, optionName, onSelect }) => {
  const [selectedCircle, setSelectedCircle] = useState(null);

  const handleClick = (index) => {
    setSelectedCircle(index);
    onSelect(optionName, options[index].value);
  };

  return (
    <div className="color-options">
      <div className="circles-container">
        <div
          className={`circle black ${selectedCircle === 0 ? "selected" : ""}`}
          onClick={() => handleClick(0)}
        ></div>
        <div
          className={`circle brown ${selectedCircle === 1 ? "selected" : ""}`}
          onClick={() => handleClick(1)}
        ></div>
        <div
          className={`circle red ${selectedCircle === 2 ? "selected" : ""}`}
          onClick={() => handleClick(2)}
        ></div>
        <div
          className={`circle blonde ${selectedCircle === 3 ? "selected" : ""}`}
          onClick={() => handleClick(3)}
        ></div>
      </div>
    </div>
  );
};
