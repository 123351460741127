import "./styles.css";
import { ResultCategories, ResultInfo } from "./InfoData";
import { useEffect, useRef, useState } from "react";
import { SkinTypeCircles } from "./SkinTypeCircles";
import { SkinTypeColors, ImagePathMap } from "./Utilities";
import { IoClose } from "react-icons/io5";
import { HairGlossTypeCard } from "./TextCard/HairGlossTypeCard";
import { SkinTypeCard } from "./TextCard/SkinTypeCard";
import { HairGlossCircles } from "./HairGlossCircles";
import { useTranslation } from "react-i18next";

export const Results = ({ results, scanType, onProductClick, onExitClick }) => {
  const { t } = useTranslation("results");
  const imgRef = useRef(null);
  const [selectedResult, setSelectedResult] = useState(
    scanType === "shyne" || scanType === "topHair"
      ? "Hair Color"
      : ResultCategories[scanType][0]
  );

  useEffect(() => {
    const image = results[ImagePathMap[selectedResult]];

    if (selectedResult === "Hair Gloss" || selectedResult === "Hair Color") {
      imgRef.current.src =
        image === "" || image === undefined
          ? `data:image/jpg;base64, ${results["originalImage"]}`
          : `data:image/jpg;base64, ${image}`;
    } else {
      imgRef.current.src =
        image === "" || image === undefined
          ? `data:image/jpg;base64, ${results["ageImage"]}`
          : `data:image/jpg;base64, ${image}`;
    }
  }, [selectedResult, results]);

  const loadShortInfo = () => {
    switch (selectedResult) {
      case "Hair Color":
        const hairColors = results.hairColorsList;
        const dominatedHairColor = results.hairDominatedColor;
        const hairDetected = results.hairStyleColor;
        return (
          <div className="hair-colors">
            {hairDetected ? (
              <div className="hair-color-bar">
                <div
                  className="average-hair-color-item"
                  style={{
                    backgroundColor: `${dominatedHairColor}`,
                  }}
                />
                {hairColors.map((hairColor, index) => (
                  <div
                    key={index}
                    className="hair-color-item"
                    style={{
                      backgroundColor: `${hairColor}`,
                    }}
                  />
                ))}
              </div>
            ) : (
              <div style={{ display: "grid", justifyContent: "center" }}>
                <h4 className="result-decision">{t("no-hair-short-info")}</h4>
              </div>
            )}
          </div>
        );
      case "Hair Gloss":
        const hairGlossType =
          results.hairStyleColor && results.hairGlossyDetected;
        return (
          <h4 className="result-decision" style={{ color: "#000" }}>
            {hairGlossType
              ? `${results.hairGlossyPercentage}% ${t("glossy-short-info")} `
              : t("no-hair-short-info")}
          </h4>
        );
      case "Skin Type":
        const skinType =
          results["skinType"].charAt(0).toUpperCase() +
          results["skinType"].slice(1);
        const skinTypeColor = SkinTypeColors[skinType];
        return (
          <h4 className="result-decision" style={{ color: skinTypeColor }}>
            {t(`${skinType} skin`)}
          </h4>
        );
      case "Irritation":
        const irritationDetected =
          results["acneDetected"] ||
          results["cancerDetected"] ||
          results["dermatitisDetected"];
        return (
          <h4
            className={`result-decision ${
              irritationDetected ? "irritation-present" : ""
            }`}
          >
            {irritationDetected
              ? t("irritation-short-info")
              : t("no-irritation-short-info")}
          </h4>
        );
      case "Skin Age":
        const age = results["age"];
        return <h4 className="result-decision">{age}</h4>;
      case "Wrinkles":
        const wrinkles = results["wrinkles"];
        return <h4 className="result-decision">{t(wrinkles)}</h4>;
      case "Puffy Eyes":
        const puffyEyes = results["puffyEye"];
        return <h4 className="result-decision">{t(puffyEyes)}</h4>;
      case "Product Recommendation":
        return;
      default:
        return <div className="result-decision"></div>;
    }
  };

  const loadResultCategories = () => {
    const resultType = ResultCategories[scanType];

    return resultType.map((resultName, index) => (
      <button
        className={`result-button ${
          resultName === selectedResult ? "selected" : ""
        } ${resultName === "Product Recommendation" ? "green" : ""}`}
        key={index}
        onClick={() =>
          resultName === "Product Recommendation"
            ? onProductClick()
            : setSelectedResult(resultName)
        }
      >
        {t(resultName)}
      </button>
    ));
  };

  const loadDetailInfo = () => {
    switch (selectedResult) {
      case "Skin Type":
        return <SkinTypeCard />;
      case "Hair Gloss":
        return <HairGlossTypeCard />;
      case "Irritation":
        const irritationInfo =
          results["acneDetected"] ||
          results["cancerDetected"] ||
          results["dermatitisDetected"]
            ? "Irritation Detected"
            : "No Irritation";
        return (
          <>
            <p className="result-detail-text">
              {t(ResultInfo[irritationInfo])}
            </p>
            {/* <button className="consultation-button">
              Start Online Consultation
            </button> */}
          </>
        );
      case "Wrinkles":
        const wrinklesInfo = results["wrinkles"];
        let defaultWrinklesInfo = "no";
        if (wrinklesInfo.toLowerCase().includes("few")) {
          defaultWrinklesInfo = "few";
        } else if (wrinklesInfo.toLowerCase().includes("many")) {
          defaultWrinklesInfo = "many";
        }
        return (
          <p className="result-detail-text">
            {t(ResultInfo[defaultWrinklesInfo])}
          </p>
        );
      default:
        return (
          <p className="result-detail-text">{t(ResultInfo[selectedResult])}</p>
        );
    }
  };

  return (
    <div className="result">
      <div className="result-short-info">
        <div className="results-image">
          <img
            ref={imgRef}
            className="background-image"
            src={`data:image/jpg;base64, ${results["ageImage"]}`}
            alt="Result"
          />
          <div className="vignette" />
          <IoClose className="close-icon" onClick={() => onExitClick()} />
        </div>
        <div className="results-info">
          <p className="result-type">
            {selectedResult !== "Product Recommendation"
              ? t(selectedResult)
              : ""}
          </p>
          {loadShortInfo()}
        </div>
        {selectedResult === "Skin Type" && (
          <div className="overlay-circles">
            <SkinTypeCircles
              oilyScore={results["skinTypeOily"]}
              dryScore={results["skinTypeDry"]}
              normalScore={results["skinTypeNormal"]}
            />
          </div>
        )}
        {selectedResult === "Hair Gloss" &&
          results.hairStyleColor &&
          results.hairGlossyDetected && (
            <div className="overlay-circles">
              <HairGlossCircles
                glossyScore={
                  results.hairGlossyPercentage === undefined ||
                  results.hairGlossyPercentage === null
                    ? 0
                    : results.hairGlossyPercentage
                }
                dullScore={
                  results.hairGlossyPercentage === undefined ||
                  results.hairGlossyPercentage === null
                    ? 0
                    : 100 - results.hairGlossyPercentage
                }
              />
            </div>
          )}
        <div className="result-types">
          <div className="result-types-button-wrapper">
            {loadResultCategories()}
          </div>
        </div>
      </div>
      <div className="result-detail-info">{loadDetailInfo()}</div>
    </div>
  );
};
