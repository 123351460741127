import { useEffect, useState } from "react";
import { Email } from "../Email/Email";
import Button from "../Button/Button";
import "./styles.css";
import { FormOptions } from "../FormOptions/FormOptions";
import UserQuestions from "./UserQuestions";
import { UpdateUserInfoUrl } from "../../constants";
import { useTranslation } from "react-i18next";
import { ColorOptions } from "../FormOptions/ColorOptions";

const questions = UserQuestions;

export const UserData = ({
  email,
  userId,
  fetchResultsComplete,
  resultsSuccess,
  fetchAIResults,
  setEmail,
  scanType,
  updateAnalysisPhase,
}) => {
  const { t } = useTranslation("userData");
  const [dots, setDots] = useState(".");
  const [isValidEmail, setIsEmailValid] = useState(false);
  const [collectionStep, setCollectionStep] = useState(0);

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({});

  useEffect(() => {
    const timerId = setTimeout(() => {
      setCollectionStep(1);
    }, 1500); // Initial wait for transition to Email Form

    return () => clearTimeout(timerId);
  }, []);

  useEffect(() => {
    if (collectionStep === 0) {
      const intervalId = setInterval(() => {
        setDots((prevDots) => {
          const newDots = prevDots === "..." ? "." : `${prevDots}.`;
          return newDots;
        });
      }, 500);

      return () => clearInterval(intervalId);
    }
  }, [collectionStep]);

  const handleOptionSelect = (optionName, selectedOption) => {
    setIsOptionSelected(true);
    setSelectedOptions((prevSelectedOptions) => {
      if (questions[scanType][activeQuestionIndex].multiAllowed) {
        const updatedOptions = [...(prevSelectedOptions[optionName] || [])];
        const optionIndex = updatedOptions.indexOf(selectedOption);

        // Allow atleast one option to be always selected
        if (optionIndex !== -1 && updatedOptions.length === 1) {
          return {
            ...prevSelectedOptions,
            [optionName]: updatedOptions,
          };
        }

        if (optionIndex !== -1) {
          updatedOptions.splice(optionIndex, 1);
        } else {
          updatedOptions.push(selectedOption);
        }
        return {
          ...prevSelectedOptions,
          [optionName]: updatedOptions,
        };
      } else {
        return {
          ...prevSelectedOptions,
          [optionName]: selectedOption,
        };
      }
    });
  };

  const sendUserData = async () => {
    try {
      const userData = { ...selectedOptions, email: email };
      await fetch(
        `${UpdateUserInfoUrl}?collectionName=tradefair&eventName=${scanType}&userId=${userId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userData),
        }
      );
    } catch (error) {
      // This could happen due to timeout so we dont catch it
    }
  };

  const onSkip = () => {
    setCollectionStep(0);
    fetchAIResults();
  };

  const onContinue = () => {
    if (scanType === "glueckauf") {
      setCollectionStep(0);
      sendUserData().then(() => fetchAIResults());
      return;
    }

    if (scanType === "techdemo") {
      setCollectionStep(0);
      sendUserData().then(() => fetchAIResults());
      return;
    }

    if (collectionStep === 1) {
      setCollectionStep(2);
      return;
    }

    if (activeQuestionIndex === Object.keys(questions[scanType]).length - 1) {
      setCollectionStep(0);
      sendUserData().then(() => fetchAIResults());
      return;
    }

    setIsOptionSelected(false);
    setActiveQuestionIndex((prevValue) => prevValue + 1);
  };

  return (
    <div className={`analyze ${collectionStep > 0 ? "split-three" : ""}`}>
      {fetchResultsComplete && resultsSuccess ? (
        <div className="analyze-progress">
          <video
            src="AnalyzeComplete.mp4"
            playsInline
            autoPlay
            muted
            width={320}
            style={{
              mixBlendMode: "darken",
              backgroundColor: "#f3f3f3",
              width: "30%",
              maxWidth: "500px",
            }}
          />
          <h4 className="analyze-title-success">
            {t("results-success-title")}
          </h4>
          <p className="analyze-info-success">{t("results-ready")}</p>
        </div>
      ) : (
        <div className="analyze-progress">
          <video
            src="Analyzing.mov"
            playsInline
            autoPlay
            loop
            muted
            style={{
              mixBlendMode: "darken",
              backgroundColor: "#f3f3f3",
              width: "80%",
              maxWidth: "500px",
            }}
          />
          {collectionStep === 0 &&
            (fetchResultsComplete ? (
              <>
                <h4 className="analyze-title-failed">
                  {t("results-fail-title")}
                </h4>
                <p className="analyze-info-failed">
                  {t("results-fail-detail")}
                </p>
              </>
            ) : (
              <p className="analyze-text-animated">
                {t("analyzing")}
                {dots}
              </p>
            ))}
        </div>
      )}
      {collectionStep === 2 && (
        <div className="analyze-content-text">
          <h4>{t(questions[scanType][activeQuestionIndex].Q)}</h4>
        </div>
      )}
      {collectionStep > 0 && (
        <div
          className={`analyze-content ${collectionStep === 1 ? "full" : ""}`}
        >
          {collectionStep === 1 ? (
            <Email
              email={email}
              isEmailValid={isValidEmail}
              setEmail={setEmail}
              setIsEmailValid={setIsEmailValid}
            />
          ) : (
            <div className="content-option">
              {questions[scanType][activeQuestionIndex].key ===
              "hairColorName" ? (
                <ColorOptions
                  options={questions[scanType][activeQuestionIndex].options}
                  optionName={questions[scanType][activeQuestionIndex].key}
                  onSelect={handleOptionSelect}
                />
              ) : (
                <FormOptions
                  options={questions[scanType][activeQuestionIndex].options}
                  optionName={questions[scanType][activeQuestionIndex].key}
                  multiAllowed={
                    questions[scanType][activeQuestionIndex].multiAllowed
                  }
                  selectedOptions={
                    selectedOptions[
                      questions[scanType][activeQuestionIndex].key
                    ]
                  }
                  onSelect={handleOptionSelect}
                />
              )}
            </div>
          )}
        </div>
      )}
      {collectionStep === 1 && scanType === "techdemo" && (
        <div className="analyze-skip-button">
          <Button
            text={t("skip")}
            onClick={() => onSkip()}
            filled={false}
            alternateColor={true}
          />
        </div>
      )}
      {collectionStep > 0 && (
        <div className="analyze-button">
          <Button
            text={t("continue")}
            onClick={() => onContinue()}
            disabled={collectionStep === 1 ? !isValidEmail : !isOptionSelected}
          />
        </div>
      )}
      {fetchResultsComplete && (
        <div className="analyze-button">
          <Button
            text={resultsSuccess ? t("continue") : t("reset")}
            onClick={() =>
              updateAnalysisPhase(resultsSuccess ? "results" : "faceScan")
            }
          />
        </div>
      )}
    </div>
  );
};
