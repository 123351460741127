import "./styles.css";

export const ProductCards = ({ products }) => {
  return (
    <div className="product-cards">
      {products.map((product, index) => (
        <div key={index} className="product-card">
          <div className="product-img-container">
            <img
              src={`data:image/png;base64, ${product.image}`}
              alt={product.title}
              className="product-img"
            />
          </div>
          <div className="product-text">
            <h4 className="product-title">{product.title}</h4>
            <p className="product-details">{product.detail}</p>
          </div>
        </div>
      ))}
    </div>
  );
};
