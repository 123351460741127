import { useState, useRef, useEffect } from "react";
import "@mediapipe/face_detection";
import "@tensorflow/tfjs-backend-webgl";
import * as faceDetection from "@tensorflow-models/face-detection";
import { InferenceSession } from "onnxruntime-web";
import { StartScan } from "../StartScan/StartScan";
import { lightPreprocess, adjustFaceCoordinates } from "./utilities";
import {
  AiResultsUrl,
  OnlyFaceScan,
  PredictionConfidence,
} from "../../constants";
import "./styles.css";
import { HairdresserPage } from "../HairdresserPage/HairdresserPage";
import { HeadScan } from "../HeadScan/HeadScan";
import { UserData } from "../UserData/UserData";
import { Results } from "../Results/Results";
import { ProductRecommendation } from "../ProductRecommendation/ProductRecommendation";
import { useTranslation } from "react-i18next";

export const Pipeline = ({ scanType, lang }) => {
  const [analysisPhase, setAnalysisPhase] = useState("faceScan");
  const [userId, setUserId] = useState(crypto.randomUUID());
  const { i18n } = useTranslation();

  const faceDetectorRef = useRef(null);
  const lightDetectorRef = useRef(null);
  const [modelsLoaded, setModelsLoaded] = useState(false);

  // Init phase for analyze + email
  const [email, setEmail] = useState("");
  const resultsRef = useRef(null);
  const [fetchResultsComplete, setFetchResultsComplete] = useState(false);
  const [resultsSuccess, setResultsSuccess] = useState(false);

  const reset = () => {
    resultsRef.current = null;
    setFetchResultsComplete(false);
    setResultsSuccess(false);
    setEmail("");
  };

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [i18n, lang]);

  useEffect(() => {
    if (analysisPhase === "userData" || analysisPhase === "faceScan") reset();
  }, [analysisPhase]);

  const fetchAIResults = () => {
    // Fetch results with Race Condition
    const timerId = setTimeout(
      () =>
        fetch(
          `${AiResultsUrl}?userId=${userId}&collectionName=tradefair&eventName=${scanType}`,
          {
            signal: AbortSignal.timeout(10000),
            method: "GET",
          }
        )
          .then((res) => {
            if (res.ok) {
              return res.json();
            }
            throw new Error("Failed to fetch!");
          })
          .then((responseJson) => {
            resultsRef.current = responseJson;
            setResultsSuccess(true);
            setFetchResultsComplete(true);
          })
          .catch((error) => {
            resultsRef.current = null;
            setResultsSuccess(false);
            setFetchResultsComplete(true);
          }),
      7000
    );

    return () => clearTimeout(timerId);
  };

  const loadModels = async () => {
    if (faceDetectorRef.current && lightDetectorRef.current) return;
    try {
      // Load Face Detector
      const model = faceDetection.SupportedModels.MediaPipeFaceDetector;
      const detectorConfig = {
        runtime: "mediapipe",
        solutionPath: "https://cdn.jsdelivr.net/npm/@mediapipe/face_detection",
      };
      faceDetectorRef.current = await faceDetection.createDetector(
        model,
        detectorConfig
      );

      // Load Light Detector
      lightDetectorRef.current = await InferenceSession.create(
        "light_det.onnx",
        { executionProviders: ["webgl"] }
      );

      setModelsLoaded(true);
    } catch (error) {
      console.error("Unable to start AI:", error);
      setModelsLoaded(false);
    }
  };

  const runFaceModel = async (video) => {
    if (faceDetectorRef.current === null) return [];

    const faces = await faceDetectorRef.current.estimateFaces(video, {
      flipHorizontal: true,
    });
    // Adjust height to get better Face Dimensions
    if (faces.length > 0) {
      faces[0] = adjustFaceCoordinates(faces[0], video);
    }
    return faces;
  };

  const runLightModel = async (imageData) => {
    const data = lightPreprocess(imageData);
    const outputData = await lightDetectorRef.current.run({ images: data });
    // Light Score 0 - 1
    const outputScore =
      PredictionConfidence[Math.round(outputData.output.data[0])];
    return outputScore;
  };

  const renderAnalysisPhase = () => {
    switch (analysisPhase) {
      case "hairdresser":
        return (
          <HairdresserPage
            onSkipClick={() => setAnalysisPhase("userData")}
            onContinueClick={() => setAnalysisPhase("headScan")}
          />
        );
      case "headScan":
        return (
          <HeadScan
            onBack={() => setAnalysisPhase("hairdresser")}
            onFinish={() => setAnalysisPhase("userData")}
          />
        );
      case "userData":
        return (
          <UserData
            userId={userId}
            email={email}
            resultsSuccess={resultsSuccess}
            fetchResultsComplete={fetchResultsComplete}
            setEmail={setEmail}
            fetchAIResults={fetchAIResults}
            scanType={scanType}
            updateAnalysisPhase={setAnalysisPhase}
          />
        );
      case "results":
        return (
          <Results
            results={resultsRef.current}
            scanType={scanType}
            onProductClick={() => setAnalysisPhase("products")}
            onExitClick={() => {
              setAnalysisPhase("faceScan");
              setUserId(crypto.randomUUID());
            }}
          />
        );
      case "products":
        return (
          <ProductRecommendation
            userId={userId}
            scanType={scanType}
            onBackClick={() => setAnalysisPhase("results")}
          />
        );
      default:
        return (
          <StartScan
            userId={userId}
            scanType={scanType}
            loadModels={loadModels}
            modelsLoaded={modelsLoaded}
            runFaceModel={runFaceModel}
            runLightModel={runLightModel}
            updateAnalysisPhase={() =>
              setAnalysisPhase(
                OnlyFaceScan.includes(scanType) ? "userData" : "hairdresser"
              )
            }
          />
        );
    }
  };

  return <div className="analysis">{renderAnalysisPhase()}</div>;
};
