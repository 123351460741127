import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
    en: {
        startPage: require('./locales/en/startPage.json'),
        faceScan: require('./locales/en/faceScan.json'),
        hairScan: require('./locales/en/hairScan.json'),
        userData: require('./locales/en/userData.json'),
        results: require('./locales/en/results.json'),
        recommendation: require('./locales/en/recommendation.json')
    },
    de: {
        startPage: require('./locales/de/startPage.json'),
        faceScan: require('./locales/de/faceScan.json'),
        hairScan: require('./locales/de/hairScan.json'),
        userData: require('./locales/de/userData.json'),
        results: require('./locales/de/results.json'),
        recommendation: require('./locales/de/recommendation.json')
    }
}

i18n
  .use(initReactI18next)
  .init({
    resources: resources,
    fallbackLng: "en",
    supportedLngs: ["de", "en"],
    interpolation: {
      escapeValue: false,
    }
  });

export default i18n;